$logo-accent-color: #BE1853; // redish
$primary-accent-color: $logo-accent-color; //#df196f;
$secondary-accent-color: #dc195d;
$tertiary-accent-color: #d300b0;
$dark-primary-accent-color: #880041;

$primary-text-color: #111;
$secondary-text-color: #515151;
$tertiary-text-color: #aaa;
$quarternary-text-color: #aaa;
$quinary-text-color: #111;

$primary-background-color: #b2b2e09c;
$secondary-background-color: #fff;
$tertiary-background-color: #dfdfdf;

$primary-gradient0: #A445B2;
$primary-gradient1: #D41872;
$primary-gradient2: #FF0066;

$secondary-gradient0: #D607F5;
$secondary-gradient1: #9E16BF;
$secondary-gradient2: #7E009E;

$quarternary-background-color: #efefef;
$quinary-dark-background-color: #777;
$quinary-background-color: #fff;

$post-title-color: #565656;
$dark-post-title-color: #c9c9c9;

$font-family: 'Ubuntu' !important;
$header-separator-height: 40px;
$sidebar-width: 300px;

$dark-secondary-background-color: rgb(26, 26, 26);

:root {
  --primary-accent-color: #{$primary-accent-color};
  --secondary-accent-color: #{$secondary-accent-color};
  --tertiary-accent-color: #{$secondary-accent-color};
  --primary-background-color: #{$primary-background-color};
  --dark-primary-accent-color: #{$dark-primary-accent-color};

  --lighter-primary-background-color: #{lighten($primary-accent-color, 10%)};
  --secondary-background-color: #{$secondary-background-color};
  --tertiary-background-color: #{$tertiary-background-color};
  --tertiary-background-transparent: #dfdfdfb8;
  --quarternary-background-color: #{$quarternary-background-color};
  --quinary-background-color: #{$quinary-background-color};

  --primary-text-color: #{$primary-text-color};
  --secondary-text-color: #{$secondary-text-color};
  --tertiary-text-color: #{$tertiary-text-color};
  --quarternary-text-color: #{$quarternary-text-color};
  --quinary-text-color: #{$quinary-text-color};

  --primary-gradient: #{$primary-accent-color};
  --secondary-gradient: #{$primary-accent-color};

  --primary-gradient0: #{$primary-gradient0};
  --primary-gradient1: #{$primary-gradient1};
  --primary-gradient2: #{$primary-gradient2};

  --secondary-gradient0: #{$secondary-gradient0};
  --secondary-gradient1: #{$secondary-gradient1};
  --secondary-gradient2: #{$secondary-gradient2};

  --font-family: #{$font-family};

  --post-title-color: #{$post-title-color};
  color-scheme: light dark;
}

$dark-logo-accent-color: $logo-accent-color;
$dark-primary-accent-color: darken($dark-logo-accent-color, 10%);
$dark-secondary-accent-color: darken($secondary-accent-color, 10%);
$dark-tertiary-accent-color: darken($tertiary-accent-color, 10%);
$dark-dark-primary-accent-color: darken($dark-primary-accent-color, 10%);

$dark-primary-text-color: #c9c9c9;
$dark-secondary-text-color: #9f9f9f;
$dark-tertiary-text-color: #888;
$dark-quinary-text-color: #eee;

$dark-primary-background-color: #000;
$dark-secondary-background-color: #222;
$dark-tertiary-background-color: #505050;
$dark-quarternary-background-color: #555;

$dark-primary-gradient0: darken($primary-gradient0, 20%);
$dark-primary-gradient1: darken($primary-gradient1, 20%);
$dark-primary-gradient2: darken($primary-gradient2, 20%);

$dark-secondary-gradient0: darken($secondary-gradient0, 40%);
$dark-secondary-gradient1: darken($secondary-gradient1, 40%);
$dark-secondary-gradient2: darken($secondary-gradient2, 40%);

$dark-font-family: 'Ubuntu' !important;
$dark-header-separator-height: 40px;
$dark-sidebar-width: 300px;

[data-color-scheme="dark"] {
  --primary-accent-color: #{$dark-primary-accent-color};
  --secondary-accent-color: #{$dark-secondary-accent-color};
  --tertiary-accent-color: #{$dark-secondary-accent-color};
  --primary-background-color: #{$dark-primary-background-color};
  --dark-primary-accent-color: #{$dark-primary-accent-color};

  --primary-text-color: #{$dark-primary-text-color};
  --secondary-text-color: #{$dark-secondary-text-color};
  --tertiary-text-color: #{$dark-tertiary-text-color};
  --quinary-text-color: #{$dark-quinary-text-color};

  --lighter-primary-background-color: #{lighten($dark-primary-accent-color, 10%)};
  --secondary-background-color: #{$dark-secondary-background-color};
  --tertiary-background-color: #{$dark-tertiary-background-color};
  --tertiary-background-transparent: #505050d1;
  --quarternary-background-color: #{$dark-quarternary-background-color};
  --quinary-background-color: #{$quinary-dark-background-color};

  --primary-gradient: #{$dark-primary-accent-color};
  --secondary-gradient: #{$dark-primary-accent-color};

  --primary-gradient0: #{$dark-primary-gradient0};
  --primary-gradient1: #{$dark-primary-gradient1};
  --primary-gradient2: #{$dark-primary-gradient2};

  --secondary-gradient0: #{$dark-secondary-gradient0};
  --secondary-gradient1: #{$dark-secondary-gradient1};
  --secondary-gradient2: #{$dark-secondary-gradient2};

  --post-title-color: #{$dark-post-title-color};

  color-scheme: light dark;
}
