
html {
  overflow-y: overlay;
  body {
    animation: color;
    //background: #f6d9ff !important;
    background-color: #8d39be !important;
    //background: #d7deff59 !important;
    //background: var(--primary-background-color) !important;
    font-family: var(--font-family);
    color: var(--primary-text-color);

    h1,h2 {
      font-weight: 100;
    }

    a {
      color: var(--primary-accent-color);
      &:hover {
        color: var(--lighter-primary-background-color);
      }
    }

    textarea:hover, 
    input:not([type='checkbox']):hover,
    textarea:active, 
    input:not([type='checkbox']):active,
    textarea:focus, 
    input:not([type='checkbox']):focus,
    button:focus,
    button:active,
    button:hover,
    label:focus,
    .btn:active,
    .btn.active
    {
        outline: 0px !important;
        -webkit-appearance:none;
        box-shadow: none !important;
        border: 0px !important;
    }

    textarea,
    input
    {
        outline: 0px !important;
        -webkit-appearance:none;
        box-shadow: none !important;
        border: 0px !important;
    }

    input, input.form-control {
      background-color: var(--quarternary-background-color);
      color: var(--primary-text-color);
      &::placeholder {
        color: var(--tertiary-text-color);
      }
      &:focus {
        color: var(--primary-text-color);
        background-color: var(--quarternary-background-color);
      }
      &:active {
        background-color: var(--quinary-background-color);
      }
    }
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: -1;
    }

    .video-container {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 30px;
      height: 0;
      overflow: hidden;
    }

    .video-container iframe, .video-container object, .video-container embed {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
    }
  }
}

@media only screen and(max-width: 768px) {
  body {
    nav.navbar {
      padding-top: 0;
      .navbar-brand {
        margin-top: 0;
        padding-top: 0;
      }
      #logo {
        margin-top: 0;
      }
      height: auto;
    }
  }
}


.container-4xl {
  width: 100%;
  //padding-right: 15px;
  //padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1800px) {
  .container-4xl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1680px;
  }
}

.overflow-y-scroll { overflow-y: scroll; }
.overflow-x-scroll { overflow-x: scroll; }
.display-none { display: none; }
.width-300px { width: 300px !important; }
.width-350px { max-width: 350px !important; }
.fix-column {
  max-width: 300px !important;
  min-width: 300px !important;
}
.width-400px { width: 400px !important; }
.noscroll {
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
}

.shadow-md { box-shadow: 0px 5px 15px 2px #0000001f !important; }
.rounded-md { border-radius: .6rem !important; }
.rounded-md-top { border-top-left-radius: .6rem !important; border-top-right-radius: .6rem !important; }
.margin-left-10 { margin-left: 10px !important; }
.margin-right-10 { margin-right: 10px !important; }
.mt-30 { margin-top: 30px; }
.border-top-accent { border-top: 10px solid var(--primary-accent-color) !important; }
.cursor-pointer { cursor: pointer; }
.theme-background {
  background-color: var(--secondary-background-color);
  color: var(--primary-text-color);
}

/* width */
::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}

::-webkit-scrollbar-thumb:horizontal{
    height: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #6666666d;
    margin: 0 auto;
    border-radius: 12px 12px 12px 12px;
    -webkit-border-radius: 12px 12px 12px 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

/* The track NOT covered by the handle.
::-webkit-scrollbar-track-piece {
    background: #000; 
}

/* Corner */
::-webkit-scrollbar-corner {
    background: #999; 
}

/* Resizer */
::-webkit-resizer {
    background: #fff0;
}

.fixed-menu {
  position: fixed;
  bottom: 0;
  height: 73px;
  z-index: 2048;
  width: 100vw;
  background-color: var(--primary-accent-color);
}

@import "bootstrap"
