body {
  #main-sidebar {
    position: sticky;
    top: 70px;
    left: 0px;
    font-size: 10pt;
    max-height: 88vh;
    overflow: hidden;
    .sidebar-content {
      background-color: var(--secondary-background-color) !important;
      height: 88vh;
      box-shadow: -13px 0px 20px 0px;
      ul.main-sidebar.nav {
        &:first {
          margin-top: 20px;
        }
        li.nav-item {
          font-size: 1.2em;
          line-height: 25px;
          a {
            color: var(--secondary-text-color);
            span.fa, svg {
              margin-right: 10px;
            }
          }
          a.active {
            color: #fff;
            background-color: var(--primary-accent-color);
          }
          a:hover {
            color: #fff;
            background-color: var(--secondary-accent-color);
          }
        }
      }
      div.main-sidebar-qliqs {
        input {
          width: 95%;
          margin-left: auto;
          margin-right: auto;
        }

        .qliq-list-container {
          height: calc(88vh - 215px - 38px - 10px);
          overflow-y: overlay;
          ul.nav {
            li.nav-item {
              font-size: 11pt;
              a {
                color: var(--secondary-text-color);
                span.fa {
                  margin-right: 10px;
                }
              }
              a.active {
                color: #fff;
                background-color: var(--primary-accent-color);
              }
              a:hover {
                color: #fff;
                background-color: var(--secondary-accent-color);
              }
            }
          }
        }
      }
    }
  }

  .sidebar {
    overflow: hidden;
    background-color: var(--secondary-background-color) !important;
    font-size: 0.95em;
    .sidebar-title {
      width: 100%;
      color: white;
      padding-left: 20px;
      padding-top: 10px;
      padding-bottom: 2px;
      h4 {
        font-size: 1.15rem; //!important;
        line-height: 25px;
      }
      //background-image: linear-gradient(-225deg, #A445B2 0%, #D41872 52%, #FF0066 100%);
      //background-color: #bf4053;
      background-color: var(--primary-accent-color);
    }
  }

  .suggestions-container {
    top: 60px;
    footer {
      color: var(--secondary-text-color);
      font-size: 10pt;
      text-align: center;
    }
  }
}