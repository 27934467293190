@import "../common/variables.scss";
$search-placeholder-color: #dca5bd;

body {
  nav#app-navbar.navbar.sticky-top {
    background-color: var(--dark-primary-accent-color);
  }

  nav#app-navbar.navbar {
    height: 42px;
    padding: 0.5rem 1rem;

    font-family: sans-serif;
    #logo {
      background-color: $logo-accent-color;
      width: 60px;
      height: 60px;
      padding: 8px;
      margin-top: 20px;
      z-index: 100;
    }

    //background-color: rgba(10,10,10,0.8) !important;
    //height: 35px;
    font-size: 0.75rem;
    .fa {
      font-size: 20px;
      &:hover {
        text-decoration: none;
      }
    }
    font-weight: 800;
    a {
      color: #efefef;
      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }
    .nav-icon {
      cursor: pointer;
      &.fa-moon-o {
        color: #b9b9b9;
      }
      &.fa-sun-o {
        color: #c9c900;
      }
    }
    input[type="text"].nav-search {
      height: 30px;
      width: 200px;
      border-radius: 6px;
      border: 0px;
      background-color: #af5f82;
      color: #efefef;
      font-size: 17px;
      padding-left: 40px;
      padding-right: 15px;
      margin-top: 0px;
      &::placeholder {
        color: $search-placeholder-color;
      }
    }
    label[for="navbar-search"] {
      position: relative;
      right: -13px;
      top: 4px;
      color: $search-placeholder-color;
      width: 0;
    }
    .key-combo {
      position: relative;
      right: 55px;
      display: flex;
      width: 0;
    }
    .key {
      margin-right: 2px;
      -webkit-align-items: center;
      align-items: center;
      background: linear-gradient(-225deg,#d5dbe4,#f8f8f8);
      border-radius: 3px;
      box-shadow: inset 0 -2px 0 0 #cdcde6,inset 0 0 1px 1px #fff,0 1px 2px 1px rgba(30,35,90,0.4);
      color: #969faf;
      height: 18px;
      -webkit-justify-content: center;
      justify-content: center;
      margin-right: .4em;
      padding-bottom: 2px;
      position: relative;
      top: -1px;
      width: 20px;
      font-size: 9px;
      padding-left: 2px;
      padding-right: 6px;
      padding-top: 2px;
    }
    .key.key-ctrl {
      &::before {
        content: "Ctrl";
      }
    }
    .key.key-k {
      padding-left: 5px;
      padding-right: 5px;
      &::before {
        content: "K";
      }
    }
  }

  .nav#app-navbar.nav-pills {
    .nav-item {
      a.active {
        background-color: var(--primary-accent-color);
      }
    }
    a.nav-link.active {
        background-color: var(--primary-accent-color);
    }
  }
}