body {
  nav#landing-navbar {
    a {
      color: white;
      text-decoration: none;
      padding: 10px 15px 10px 15px;
      margin-right: 20px;
    }
    a#signup-button {
      background-color: #ffffff33;
      border-radius: 10px;
      font-weight: 700;
      &:hover {
        background-color: #ffffff55;
      }
    }
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    height: 70px;
  }
}