body {
  div.page-container#landing-container {
    width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }
}

.page-size {
  //width: var(--window-width);
  //height: var(--window-height);
  width: 100vw;
  height: 100vh;
  padding-top: 140px;
  padding-left: 40px;
  padding-right: 40px;
}