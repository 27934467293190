@import "../common/variables.scss";

body {
  header {
    background-color: var(--primary-accent-color);
    min-height: 200px;
    //margin-bottom: -60px;
    margin-bottom: -30px;
    border-bottom: 4px solid var(--primary-accent-color);

    #header-logo {
      padding-left: 13%;
      background: rgba(0, 0, 0, 0.3);
      height: 160px;
    }
    .header-motto {
      font-weight: 100;
      color: white;
      font-size: 20pt;
      margin-left: 40px;
      position: absolute;
      margin-top: 100px;
      margin-left: 20px;
    }
    .header-head {
      position: sticky;
      position: -webkit-sticky;
      top: 35px;

      display: block;
      font-weight: 100;
      background: rgba(0, 0, 0, 0.3);
      //width: 540px;
      //width: 45vw;
      width: calc(100vw / 3);
      padding-right: 30px;
      padding-bottom: 30px;
      backdrop-filter: blur(10px);
      border-bottom-right-radius: 10px;

      .header-title {
        color: white;
        //font-size: 45pt;
        font-size: calc(4pt + 3.1vw);
        margin-left: 30%;
        display: block;
        text-decoration-line: underline;
        text-decoration-color: #ffff00bf;
      }
      .header-subtitle {
        color: white;
        //font-size: 18pt;
        font-size: calc(1.525rem + 0.1vw);
        margin-left: 30%;
        display: block;
      }
    }
  }
  #header-separator {
    min-height: $header-separator-height;
    background-color: var(--secondary-background-color) !important;
    z-index: 5;
    width: 100vw;
    //background-color:#f7f7f7;
    border-bottom: 1px solid #f3f3f3;
    margin-bottom: -15px;
    .sidebar-placer {
      /*
      height: $header-separator-height;
      min-width: $sidebar-width;
      max-width: $sidebar-width;
      */
      max-width: $sidebar-width;
    }
  }
}